import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'dateTimeWithTimezoneFormat'
})
export class DateTimeTimezoneFormatterPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value != null) {
      return super.transform(new Date(value), environment.dateTimeFormatUK);
    } else {
      return '';
    }
  }
}
