<div>
    <mat-card
        class="main-view"
    >
        <app-msg-banner
            (msgListChange)="messageList = $event"
            *ngIf="showNotification"
            [msgList]="messageList"
        ></app-msg-banner>

        <!--    filters -->
        <form [formGroup]="searchForm">
            <mat-card
                class="no-padding mat-elevation-z0"
            >
                <mat-accordion>
                    <mat-expansion-panel
                        [expanded]="true"
                    >
                        <mat-expansion-panel-header style="height: auto !important;">
                            <span class="material-icons filter-title">filter_alt</span>
                            <span class="filter-title"> Filter </span>
                        </mat-expansion-panel-header>

                        <div
                            fxLayout="row wrap"
                            fxLayout.lt-md="column"
                            fxLayoutGap="3%"
                            fxLayoutGap.lt-md="0%"
                        >

                            <mat-form-field appearance="outline" fxFlex="30%" fxFlex.lt-md="100%">
                                <mat-label>Date Range</mat-label>
                                <mat-date-range-input [rangePicker]="dateRangePicker">
                                    <input formControlName="rangeStart" matStartDate placeholder="Start date">
                                    <input formControlName="rangeEnd" matEndDate placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle [for]="dateRangePicker" matSuffix></mat-datepicker-toggle>
                                <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="30%" fxFlex.lt-md="100%">
                                <mat-label>Process Name</mat-label>
                                <mat-select [formControl]="searchForm.controls.names" multiple>
                                    <mat-option *ngFor="let name of processNameValues" [value]="name">
                                        {{ name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="30%" fxFlex.lt-md="100%" >
                                <mat-label> Success </mat-label>
                                <mat-select formControlName="isSuccess" panelWidth="">
                                    <mat-option>No selection</mat-option>
                                    <mat-option *ngFor="let value of isSuccessValues" [value]="value">
                                        {{ value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!--            buttons -->
                        <div
                            fxLayout="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap="10px"
                        >
                            <button
                                (click)="onSearch()"
                                class="btn submit-button"
                                fxFlex="100px"
                                fxFlex.lt-md="100%"
                                mat-raised-button
                                type="button"
                            > SEARCH </button>
                            <button
                                (click)="searchForm.reset()"
                                class="btn reset-button"
                                fxFlex="100px"
                                fxFlex.lt-md="100%"
                                mat-raised-button
                                type="button"
                            > CLEAR </button>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card>
        </form>


        <!--    table data -->
        <mat-card
            class="no-padding mat-elevation-z1"
        >
            <app-ghost-table
                *ngIf="reload"
                [columns]="columns"
                [displayedColumns]="displayedColumns"
            ></app-ghost-table>

            <div *ngIf="!reload" class="table-overflow fixed-height">
                <table
                    (matSortChange)="changeSort($event)"
                    [dataSource]="dataSource"
                    mat-table
                    matSort
                >
                    <ng-container *ngFor="let col of columns" [matColumnDef]="col.colDef">
                        <!-- Column Header -->
                        <th *matHeaderCellDef class="{{ col.colWidthClass }}" mat-header-cell mat-sort-header>
                            {{ col.colHeader }}
                        </th>

                        <!-- Column Content -->
                        <span *ngIf="col.colType === getColumnType.DATE">
          <td *matCellDef="let element" mat-cell>
            {{ element[col.colDef] | dateFormat }}
          </td>
        </span>
                        <span *ngIf="col.colType === getColumnType.DATE_TIME">
          <td *matCellDef="let element" mat-cell>
            {{ element[col.colDef] | dateTimeWithTimezoneFormat }}
          </td>
        </span>
                        <span *ngIf="col.colType !== getColumnType.DATE && col.colType !== getColumnType.DATE_TIME && col.colType !== getColumnType.URL">
          <td *matCellDef="let element" mat-cell>
            {{ element[col.colDef] }}
          </td>
        </span>
                    </ng-container>

                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr
                        *matRowDef="let row; columns: displayedColumns;"
                        mat-row
                    ></tr>

                </table>
            </div>

            <mat-paginator
                (page)="onPageChange($event)"
                [length]="length"
                [pageSizeOptions]="[ 50, 100, 200, 300, 500 ]"
                [pageSize]="50"
            ></mat-paginator>
        </mat-card>
    </mat-card>
</div>
